import { Controller } from "@hotwired/stimulus"
import { enter, leave, toggle } from "el-transition";

class Files {
  constructor(array) {
    this.array = array;
    this.index = 0;
  }

  setCurrent(fileID) {
    this.index = this.array.findIndex(element => element.id == fileID);
  }

  getCurrent() {
    return this.array[this.index];
  }

  size() {
    return this.array.length;
  }

  prevFileId() {
    if (this.index > 0) {
      return this.index-1
    } else {
      return null
    }
  }

  nextFileId() {
    if (this.index < this.size() - 1) {
      return this.index+1
    } else {
      return null
    }
  }

}

// Connects to data-controller="slideshow"
export default class extends Controller {
  static targets = ["wrapper", "backgroundBackdrop", "pict", "pictLabel"]


  connect() {
    // console.log("Hello from slideshow controller!");
    this.files = new Files(gon.files.array)
  }

  open(event) {
    enter(this.wrapperTarget);
    enter(this.backgroundBackdropTarget);
    this.files.setCurrent(event.params.file)
    this.pictTarget.innerHTML = this.imageTag()
    this.pictLabelTarget.innerHTML = this.labelTag()
  }

  close(event) {
    leave(this.wrapperTarget);
    enter(this.backgroundBackdropTarget);
  }

  inhibitClosing(event) {
    event.stopPropagation();
  }

  escape(event) {
    this.close();
  }

  nextPict(event) {
    if (this.files.nextFileId() != null) {
      this.files.index += 1;
      this.pictTarget.innerHTML = this.imageTag()
      this.pictLabelTarget.innerHTML = this.labelTag()
    }
  }

  prevPict(event) {
    if (this.files.prevFileId() != null) {
      this.files.index -= 1;
      this.pictTarget.innerHTML = this.imageTag()
      this.pictLabelTarget.innerHTML = this.labelTag()
    }
  }

  imageTag() {
    // return `<img src="${this.files.getCurrent().url}" class="w-full h-full object-cover" />`
    // return `<div class="relative"><div class="absolute inset-0"><img src="${this.files.getCurrent().url}" class="w-full h-full object-cover" /></div><div class="absolute inset-0 flex flex-row"><div class="basis-1/2">01</div><div class="basis-1/2">02</div></div></div>`
    return `<div class="relative"><img src="${this.files.getCurrent().url}" class="w-full h-full object-cover" /><div class="absolute inset-0 flex flex-row"><div class="basis-1/2 cursor-pointer" data-action="click->slideshow#prevPict"></div><div class="basis-1/2 cursor-pointer" data-action="click->slideshow#nextPict"></div></div></div>`
    // return `<img src="${this.files.getCurrent().url}" class="relative w-full h-full object-cover" /></div><div class="absolute inset-0 flex flex-row"><div class="basis-1/2">01</div><div class="basis-1/2">02</div>`
  }

  labelTag() {
    return `<div class="">${this.files.getCurrent().name}</div>`;
  }

}
