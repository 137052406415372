import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="buildings"
export default class extends Controller {
  // static values = {
  //   url: String
  // }


  connect() {
    // console.log("Hello from buildings controller!");
  }

  show(event) {

    // console.log("Hello from buildings#show action!");
    // console.log(event.target);
    // console.log(event.target.target);
    // console.log(event.target.href);
    // console.log(event.params);

    if (event.target.href) {
      // the element has an href attribute: it's a link!
      event.stopPropagation();
      event.preventDefault();
      if (event.target.target=="_blank") {
        window.open(event.target.href, '_blank');
      } else {
        Turbo.visit(event.target.href);
      }
    } else {
      Turbo.visit(event.params.url);
    }
  }

}
