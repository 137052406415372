import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css'

// used in Building#edit


class LngLat {
  constructor(lngLat) {
    this.lng = lngLat.lng;
    this.lat = lngLat.lat;
  }

  coordinates() {
    return [this.lng, this.lat];
  }
}

class CoordHistory {
  constructor() {
    this.history = [];
    this.index = 0;
  }

  push(lngLat) {
    // console.log("pushing");
    if (this.index < this.history.length - 1) {
      this.history = this.history.slice(0, this.index + 1);
    }
    this.history.push(new LngLat(lngLat));
    this.index = this.history.length - 1;
  }

  get() {
    return this.history[this.index];
  }
  
  prev() {
    if (this.index > 0) {
      this.index -= 1;
    }
    return this.get();
  }

  next() {
    if (this.index < this.history.length - 1) {
      this.index += 1;
    }
    return this.get();
  }

  size() {
    return this.history.length;
  }
}



// Connects to data-controller="pickable-map"
export default class extends Controller {
  static values = { token: String }
  static targets = [ "prev", "next", "coordinates"]

  // https://docs.mapbox.com/mapbox-gl-js/example/cluster/

  histPrev(e) {
    e.preventDefault();
    // this._onMove_(this.coordinatesHistory.prev())
    this.updatePosition(this.coordinatesHistory.prev(), false)
  }

  histNext(e) {
    e.preventDefault();
    // this._onMove_(this.coordinatesHistory.next())
    this.updatePosition(this.coordinatesHistory.next(), false)
  }

  addressOnMap(e) {
    e.preventDefault();
    // console.log("addressOnMap");
    const address = $('#building_street').val() + ' ' + $('#building_number').val() + ', ' + $('#building_zip').val() + ' ' + $('#building_city').val() + ', ' + $('#building_country').val()
    this.goToAddress(address)
  }

  goToAddress(address) {
    let url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
      + encodeURIComponent(address) + '.json?access_token='
      + mapboxgl.accessToken + '&limit=1';

      fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let longitude = data.features[0].center[0]
        let latitude = data.features[0].center[1]
        // let location = data.features[0].place_name
        // console.log("Latitude :", latitude);
        // console.log("Longitude :", longitude);

        this.updatePosition(new LngLat({ lng: longitude, lat: latitude }));
      });
  }


  getLocation() {
    // console.log("Getting location...");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition, this.positionNotFound);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  positionNotFound() {
    alert("Not able to get your position.");
  }

  showPosition = (position) => {
    this.updatePosition(new LngLat({ lng: position.coords.longitude, lat: position.coords.latitude }));
  }



  updatePosition(lngLat, push = true) {
    this.coordinatesTarget.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;
    if (push) {
      this.coordinatesHistory.push(lngLat);
    }
    this.geojson.features[0].geometry.coordinates = lngLat.coordinates();
    if (this.map.getSource('point')) {
      this.map.getSource('point').setData(this.geojson);
    }
    this.map.flyTo({
      center: lngLat.coordinates()
    });
    // console.log(this.coordinatesHistory.size());

    $('#building_latitude').val(lngLat.lat);
    $('#building_longitude').val(lngLat.lng);

    // history buttons
    if (this.coordinatesHistory.size() > 1) {
      if (this.coordinatesHistory.index == 0) {
        this.prevTarget.disabled = true;
        this.nextTarget.disabled = false;
      } else if (this.coordinatesHistory.index == this.coordinatesHistory.size() - 1) {
        this.prevTarget.disabled = false;
        this.nextTarget.disabled = true;
      } else {
        this.prevTarget.disabled = false;
        this.nextTarget.disabled = false;
      }
    } else { // size == 1
      this.prevTarget.disabled = true;
      this.nextTarget.disabled = true;
    }

  }

  connect() {
    this.defaultLongitude = 10.922616141922168;
    this.defaultLatitude = 44.63913595705431;

    const currentBuildingLongitude = document.getElementById('building_longitude').value;
    const currentBuildingLatitude = document.getElementById('building_latitude').value;

    this.coordinatesHistory = new CoordHistory();
    const currLngLat = new LngLat({ lng: (currentBuildingLongitude || this.defaultLongitude), lat: (currentBuildingLatitude || this.defaultLatitude) })
    this.coordinatesHistory.push(currLngLat);

    this.initMap();


    this.map.on('load', () => {
      // Add a single point to the map.
      this.map.addSource('point', {
        'type': 'geojson',
        'data': this.geojson
      });

      this.map.addLayer({
        'id': 'point',
        'type': 'circle',
        'source': 'point',
        'paint': {
          'circle-radius': 10,
          'circle-color': '#F84C4C' // red color
        }
      });

      // When the cursor enters a feature in
      // the point layer, prepare for dragging.
      this.map.on('mouseenter', 'point', () => {
        this.map.setPaintProperty('point', 'circle-color', '#3bb2d0');
        this.canvas.style.cursor = 'move';
      });

      this.map.on('mouseleave', 'point', () => {
        this.map.setPaintProperty('point', 'circle-color', '#3887be');
        this.canvas.style.cursor = '';
      });

      this.map.on('mousedown', 'point', (e) => {
        // Prevent the default map drag behavior.
        e.preventDefault();

        this.canvas.style.cursor = 'grab';

        this.map.on('mousemove', this.onMove);
        this.map.once('mouseup', this.onUp);
      });

      this.map.on('touchstart', 'point', (e) => {
        if (e.points.length !== 1) return;

        // Prevent the default map drag behavior.
        e.preventDefault();

        this.map.on('touchmove', this.onMove);
        this.map.once('touchend', this.onUp);
      });
    });

    this.updatePosition(currLngLat, false)
  }


  initMap() {
    // TO MAKE THE MAP APPEAR YOU MUST
    // ADD YOUR ACCESS TOKEN FROM
    // https://account.mapbox.com
    mapboxgl.accessToken = this.tokenValue;
    let coords = this.coordinatesHistory.get().coordinates();
    // this.coordinates = document.getElementById('coordinates'); // <===========

    this.map = new mapboxgl.Map({
      container: 'map',
      // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
      style: 'mapbox://styles/mapbox/streets-v12',
      center: coords,
      zoom: 13
    });

    this.canvas = this.map.getCanvasContainer();

    this.geojson = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': coords
          }
        }
      ]
    };
  }


  onMove = (e) => {
    this._onMove_(e.lngLat);
  }

  _onMove_ = (coords) => {
    // Set a UI indicator for dragging.
    this.canvas.style.cursor = 'grabbing';

    // Update the Point feature in `geojson` coordinates
    // and call setData to the source layer `point` on it.
    this.geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
    this.map.getSource('point').setData(this.geojson);
  }


  onUp = (e) => {
    this._onUp_(e.lngLat);
  }

  _onUp_ = (coords) => {
    // Print the coordinates of where the point had
    // finished being dragged to on the map.
    this.coordinatesTarget.style.display = 'block';
    // this.coordinates.style.display = 'block';
    this.canvas.style.cursor = '';

    this.updatePosition(new LngLat(coords))

    // Unbind mouse/touch events
    this.map.off('mousemove', this.onMove);
    this.map.off('touchmove', this.onMove);
  }
}
