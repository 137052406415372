import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
import 'mapbox-gl/dist/mapbox-gl.css'

// Connects to data-controller="map"
export default class extends Controller {
  static values = { url: String }

  // https://docs.mapbox.com/mapbox-gl-js/example/cluster/

  connect() {
    setTimeout(() => {
      this.foo()
    }, 200)

  }

  foo() {
    mapboxgl.accessToken = this.urlValue;

    const RED   = "#dc143c"
    const GREEN = "#008000"
    const GRAY  = "#808080"

    let locations = gon.buildings; // gon.watch('buildings');
    var map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: locations[0].lngLat, // Set the initial center of the map to the first location
      zoom: 10 // Adjust the zoom level as needed
    });

    if (locations.length > 1) {
      // Calculate the map's bounding box to fit all locations
      var bounds = new mapboxgl.LngLatBounds();
      locations.forEach(function (location) {
        bounds.extend(location.lngLat);
      });

      // Set the map's center and zoom to fit the bounding box
      map.fitBounds(bounds, {
        padding: 40 // Adjust the padding as needed
      });
    }

    // Add markers for each location
    locations.forEach(function (location) {
      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<a href="${location.url}" target="_blank" class="text-gray-600">${location.label}</a>`
      );
      let color = location.status == "vacant" ? GREEN : (location.status == "occupied" ? RED : GRAY)
      new mapboxgl.Marker({color: color})
        .setLngLat(location.lngLat)
        .setPopup(popup)
        .addTo(map);
    });
  }
}
