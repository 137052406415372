import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="latlong"
export default class extends Controller {

  connect() {
    // console.log("Hello, Stimulus!");
  }

  getLocation() {
    // console.log("Getting location...");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  showPosition(position) {
    // console.log("Latitude: " + position.coords.latitude + "Longitude: " + position.coords.longitude);
    document.getElementById('building_latitude').value = position.coords.latitude;
    document.getElementById('building_longitude').value = position.coords.longitude;
  }

}
