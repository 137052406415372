import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

// Connects to data-controller="attachments"
export default class extends Controller {
  static targets = ["fullsize", "backgroundBackdrop"]

  connect() {
  }

  show(event) {
  }

}
