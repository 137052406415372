import { Controller } from "@hotwired/stimulus"
import { enter, leave, toggle } from "el-transition";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["wrapper", "backgroundBackdrop", "panel"]

  connect() {
    console.log("Hello from modal controller!");
  }

  open(event) {
    console.log("open!");
    // event.stopPropagation();
    event.preventDefault();
    enter(this.wrapperTarget);
    enter(this.backgroundBackdropTarget);

    this.panelTarget.innerHTML = `<img src="${event.params.url}" class="w-full h-full object-cover" />`;
  }

  close(event) {
    leave(this.wrapperTarget);
    enter(this.backgroundBackdropTarget);
  }

  inhibitClosing(event) {
    event.stopPropagation();
  }

  escape(event) {
    this.close();
  }

}
