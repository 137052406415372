import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="building-status"
export default class extends Controller {

  connect() {
    // console.log("BuildingStatusController");

    if ($("input[value='occupied']").prop('checked')) {
      this.enableButtons()
    } else {
      this.disableButtons()
    }

    $("#statusButtons button").on("click", {that: this}, function(event) {
      if ($(this).prev().val() == "occupied") {
        event.data.that.enableButtons()
      } else {
        event.data.that.disableButtons()
      }
    })
  }

  disableButtons() {
    $('#formOfOccupationButtons').addClass('opacity-10')
    $("#formOfOccupationButtons button").attr("disabled", true)
 }

  enableButtons() {
    $('#formOfOccupationButtons').removeClass('opacity-10')
    $("#formOfOccupationButtons button").attr("disabled", false)
  }
}